import React from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation

const ErrorPage = () => {
    const location = useLocation(); // Access the current location object
    const errorMessage = location.state?.message || 'Something has gone wrong. Please try again later.'; // Access the message passed through state

    return (
        <div className="error-container">
            <div className="error-content">
                <h1>Error Occurred</h1>
                <p>{errorMessage}</p> 
            </div>
        </div>
    );
}

export default ErrorPage;
