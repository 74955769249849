import React, { useCallback, useState, useContext } from 'react';
import SkeletonLoader from '../../utils/SkeletonLoader';
import { useDropzone } from 'react-dropzone';
import { TokenContext } from '../../Context/TokenContext'; 
import { getApiBaseUrl } from '../../utils/Config';
import { AlertContext } from '../../Context/AlertContext';
import { useAuth0 } from '@auth0/auth0-react';

const CandidateProfilePicture = ({ candidateData, profilePic, candidateID }) => {
    const { isAuthenticated } = useAuth0();

    const { token, idTokenClaims } = useContext(TokenContext);
    const profilepicuri = profilePic.Uri;
    const [preview, setPreview] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [saving, setSaving] = useState(false);
    const baseUrl = getApiBaseUrl();
    const { showAlert } = useContext(AlertContext);

    const onFileChange = useCallback((acceptedFiles, fileRejections) => {
        if (acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
            setSelectedFile(file);
            const objectURL = URL.createObjectURL(file);
            setPreview(objectURL);
        }

        if (fileRejections.length > 0) {
            fileRejections.forEach(rejection => {
                if (rejection.errors.some(error => error.code === 'file-too-large')) {
                    showAlert(`File "${rejection.file.name}" is too large.`, false);
                } else if (rejection.errors.some(error => error.code === 'file-invalid-type')) {
                    showAlert(`File "${rejection.file.name}" has an invalid type.`, false);
                } else {
                    showAlert(`File "${rejection.file.name}" was rejected.`, false);
                }
            });
        }
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: onFileChange,
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpg', '.jpeg'],
            'image/bmp' : ['.bmp']
        }, 
        multiple: false,
        maxSize: 2 * 1024 * 1024  // Limit to 2MB
    });
    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                console.log("Base64 Loaded:", reader.result);
                resolve(reader.result.split(",")[1]);
            };
            reader.onerror = error => {
                console.error("Error while reading the file:", error);
                reject(error);
            };
        });
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!selectedFile) {
            showAlert('Please select a file before submitting', false);
            return;
        }
        setSaving(true);

        const base64File = await getBase64(selectedFile);
        const payload = {
            FIleBase64: base64File,
            CandidateID: candidateID,
            DateTimeCreated: new Date().toISOString(),
            DateTimeModified: new Date().toISOString(),
            CreatedBy: 'Admin',
            ModifiedBy: 'User',
            IsDeleted: false,
            B2CObjectId: idTokenClaims.oid,
            AttachmentTypeId: 1,
            FileName: selectedFile.name
        };
        try {
            const response = await fetch(
                `${baseUrl}/api/Attachment/UploadCandidateAttachment`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(payload),
                }
            );
            const responseData = await response.json();

            if (responseData.success) {
                showAlert('Success', true);
            } else {
                const data = await response.json();
                showAlert(data.message || 'Oops, something went wrong. Please try again later.', false);
            }
        }
        catch (error) {
            showAlert('Oops, something went wrong. Please try again later.', false);
        }
        finally {
            setSaving(false);
        }
    };
    if (!isAuthenticated) {
        return <div>Please log in to access the dashboard.</div>;
    }
    if (!candidateData) {
        return <SkeletonLoader />;
    }
    return (
        <form id="ProfilePic-add-form" onSubmit={handleSubmit}>
            <div className="col-auto image-upload" {...getRootProps()}>
                <input name="File" {...getInputProps()} />
                <label htmlFor="file_upload_pp">
                    <span className="PlaceHolder-Sub-PP">
                        <span className="PlaceHolder-PP">
                            {preview ? (
                                <img id="ProPic" src={preview} alt="Selected Profile Preview" />
                            ) : profilepicuri ? (
                                    <img id="ProPic" src={`${profilepicuri}?timestamp=${Date.now()}`} alt={`${candidateData.FirstName} profile picture`} />
                            ) : (
                                <>
                                    {candidateData.FirstName[0].toUpperCase()}
                                    {candidateData.LastName[0].toUpperCase()}
                                </>
                            )}
                        </span>
                    </span>
                </label>
            </div>
            {selectedFile && <button type="submit" className="btn btn-sm btn-primary" disabled={saving}>{saving ? 'Saving...' : 'Save'}</button>}
        </form>
    );
}

export default CandidateProfilePicture;
