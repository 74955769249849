// Header.js
import React from 'react';
import logoImage from '../../images/logohome.png';
import { useAuth0 } from '@auth0/auth0-react';
import ProfileProgress from './ProfileProgress';
import { Link } from 'react-router-dom';

function CandidateHeader() {
    const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();
    const handleLogout = () => {
        logout({ returnTo: window.location.origin });
    };
    return (
        <nav className="navbar show navbar-vertical h-lg-screen navbar-expand-lg px-0 py-3 navbar-dark border-bottom border-bottom-lg-0 border-end-lg" id="sidebar">
            <div className="container-fluid">
                <Link className="navbar-brand d-inline-block py-lg-2 mb-lg-5 px-lg-6 me-0" to="/candidate/profile">
                    <img src={logoImage} className="csshomelogo" alt="talentup logo"></img>
                </Link>
             
                <div className="navbar-user d-lg-none">
                    <div className="dropdown">
                        <div className="dropdown-menu dropdown-menu-end" aria-labelledby="sidebarAvatar">
                            <a href="#" className="dropdown-item">Profile</a>
                            <a href="#" className="dropdown-item">Settings</a>
                            <a href="#" className="dropdown-item">Billing</a>
                            <hr className="dropdown-divider"></hr>
                        </div>
                    </div>
                </div>
                <button className="navbar-toggler ms-n2" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarCollapse" aria-controls="sidebarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="sidebarCollapse">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <Link className="nav-link py-2" to="/candidate/profile"><i className="bi bi-person-circle"></i> Edit Profile</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link py-2" to="/candidate/businessview"><i className="bi bi-file-text"></i> How Businesses See You</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link py-2" to="/candidate/jobs"><i className="bi bi-briefcase"></i> Jobs Applications</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link py-2" to="/candidate/settings"><i className="bi bi bi-gear"></i> Settings</Link>
                        </li>
                    </ul>
                    <hr className="navbar-divider my-4 opacity-70"></hr>
                    <div className="profile-progress vstack">
                       <ProfileProgress/>
                    </div>

                    <div className="mt-auto"></div>
                    <hr className="navbar-divider my-4 opacity-70"></hr>
                    <div className="position-relative">
                        <div className="dropup w-full">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <a className="nav-link py-2" href="#" onClick={handleLogout}><i className="bi bi-box-arrow-left"></i> Logout</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </nav >

    );
}

export default CandidateHeader;
