import React from 'react';
function topNav({ titleName }) {
  
    return (
            <div className="top_header-attapo border-bottom px-0 py-3">
                <div className="container-fluid">
                    <div className="hstack gap-2">
                        <h2>{titleName}</h2>
                    </div>
                </div>
            </div>
          
    );
}

export default topNav;
