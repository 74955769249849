import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate, Link } from 'react-router-dom';
import JobSearch from '../pages/JobSearch';
import SkeletonLoader from '../utils/SkeletonLoader';
import { getApiBaseUrl } from '../utils/Config';

function Jobs() {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState({
        what: searchParams.get('what') || '',
        where: searchParams.get('where') || ''
    });
    const baseUrl = getApiBaseUrl();

    const [currentPage, setCurrentPage] = useState(parseInt(searchParams.get('pageNumber')) || 1); 
    const [totalPages, setTotalPages] = useState(0);
    const [jobResults, setJobResults] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    useEffect(() => {
        setSearchQuery({
            what: searchParams.get('what') || '',
            where: searchParams.get('where') || ''
        });
        setCurrentPage(parseInt(searchParams.get('pageNumber')) || 1);
    }, [searchParams]);
    useEffect(() => {
        const fetchJobs = async () => {
            const what = searchParams.get('what') || searchQuery.what;
            const where = searchParams.get('where') || searchQuery.where;
            const pagenumber = searchParams.get('pageNumber') || searchQuery.pageNumber;
            setIsLoading(true);
            setError(null);

            try {
                const response = await fetch(`${baseUrl}/api/JobSearch/JobSearch?what=${encodeURIComponent(what)}&where=${encodeURIComponent(where)}&pageNumber=${encodeURIComponent(pagenumber)}`);
                console.log("respone", response);
                if (!response.ok) throw new Error('Error fetching jobs');
                const data = await response.json();
                setJobResults(data.result.items || []);
                setTotalPages(data.result.totalPages);
            } catch (error) {
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        };

        fetchJobs();
    }, [searchParams, searchQuery.what, searchQuery.where, searchQuery.pageNumber]); 
    // Render a header based on search query
    const renderHeader = () => {
        const what = searchParams.get('what');
        if (what) {
            return <h3>Search Results for <span className="search-results-tag">{what}</span> jobs</h3>;
        }
        return <h3>Search Result for <span className="search-results-tag">All Jobs</span></h3>;
    };
    function getSalaryRangeText(salaryRangeValue) {
        const salaryRanges = {
            1: "Under $30,000",
            2: "$30,000 - $50,000",
            3: "$50,000 - $70,000",
            4: "$70,000 - $90,000",
            5: "$90,000 - $110,000",
            6: "$110,000 - $130,000",
            7: "$130,000 - $150,000",
            8: "$150,000 - $170,000",
            9: "$170,000 - $190,000",
            10: "$190,000 - $210,000",
            11: "$210,000 - $230,000",
            12: "$230,000 - $250,000",
            13: "$250,000 and above",
            14: "Negotiable",
            15: "Competitive",
        };

        return salaryRanges[salaryRangeValue] || "Not specified";
    }
    function getWorkTypeText(workTypeValue) {
        const workTypes = {
            1: "Full-Time",
            2: "Part-Time",
            3: "Casual",
            4: "Contract",
            5: "Temporary",
            6: "Freelance",
            7: "Internship",
            8: "Consultancy",
            9: "Apprenticeship",
        };

        return workTypes[workTypeValue] || "Not specified";
    }
    const handlePageChange = (newPageNumber) => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setSearchParams({ what: searchQuery.what, where: searchQuery.where, pageNumber: newPageNumber });
    };
    const handleLinkClick = (e, jobId) => {
        e.preventDefault();
        navigate(`/job/${jobId}`);
    };

    const renderPagination = () => {
        let pages = [];
        for (let i = 1; i <= totalPages; i++) {
            pages.push(
                <li key={i} className={`page-item ${i === currentPage ? 'active' : ''}`}>
                    <a className="page-link" onClick={() => handlePageChange(i)}>{i}</a>
                </li>
            );
        }
        return pages;
    };
    const calculateTimeElapsed = (startDateString) => {
        const startDate = new Date(startDateString);
        const now = new Date();
        const timeDifference = now - startDate; // difference in milliseconds

        // Calculate time difference in various units
        const minutes = Math.floor(timeDifference / 60000);
        const hours = Math.floor(timeDifference / 3600000);
        const days = Math.floor(timeDifference / (3600000 * 24));

        // Format the output
        if (minutes < 60) {
            return `${minutes} min ago`;
        } else if (hours < 24) {
            return `${hours} hours ago`;
        } else {
            return `${days} days ago`;
        }
    };

    const isValidLocation = (location) => {
        const validLocations = ["ACT", "NSW", "NT", "QLD", "SA", "TAS", "VIC", "WA"];
        return validLocations.includes(location) ? location : null;
    };
    return (
        <>
            <JobSearch />
            <div class="container-xxl">
                <div id="search-results">
                    <div className="se_muj_as023 mb-5">
                        <div className="top-data-search">
                            <div className="container-fluid">
                                <div className="top-data-search-inner">
                                    <div className="row align-items-center">
                                        <div className="col-sm col-12">
                                            {renderHeader()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="search-result_allpk container-fluid">
                        <div className="row mb-6">
                        
                            <div className="col-sm-12 col-md-12">
                                <div className="gap-3 col-xs-12 col-md-12">
                                    <div className="advert-pa-ou-lay">
                                        <>
                                            {isLoading ? <SkeletonLoader type="JobList" /> : error ? <p>Whoops, something went wrong.: {error}</p> :
                                                jobResults.map(job => (
                                                    <article key={job.JobPostID} className={job.IsUrgentlyHiring ? "css-dlxf47 mb-4" : "css-dlxf46 mb-4"}>
                                                        <div className="vstack gap-6">
                                                            <div className="card">
                                                                <div className="card-body">
                                                                    <div className="job_post_header">
                                                                        <div className="d-flex align-items-center advert-pa-ou-iuj">
                                                                            <h2 className="css-12koetd me-auto">
                                                                                <a href={`/job/${job.JobPostID}`} onClick={(e) => handleLinkClick(e, job.JobPostID)}>
                                                                                    {job.Title}
                                                                                </a>
                                                                            </h2>
                                                                            {
                                                                                job.IsUrgentlyHiring ? (
                                                                                    <div>
                                                                                        <span className="badge text-uppercase bg-soft-primary text-primary rounded-pill">Featured</span>
                                                                                    </div>
                                                                                ) : (
                                                                                        <p>{calculateTimeElapsed(job.StartDate)}</p>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="job_post_sub_header mb-5">
                                                                        <div className="mb-2">
                                                                            <a href="#">
                                                                                <h5>
                                                                                    <span className="css-12koeto">{job.BusinessName} {isValidLocation(job.Location) ? `| ${job.Location}` : ''}</span>
                                                                                </h5>
                                                                            </a>
                                                                        </div>
                                                                        <div className="mb-2">
                                                                            {job.SalaryRange > 0 && (
                                                                                <span className="badge bg-cust-tag text-cust-d-pur me-2">
                                                                                    <i className="bi bi-coin"></i> {getSalaryRangeText(job.SalaryRange)}
                                                                                </span>
                                                                            )}

                                                                            <span className="badge bg-cust-tag text-cust-d-pur me-2"><i className="bi bi-briefcase"></i> {getWorkTypeText(job.WorkTypeID)}</span>
                                                                            {job.IsSponsorship && (
                                                                                <span className="badge bg-cust-tag text-cust-d-pur me-2"><i className="bi bi-airplane"></i> Open to Sponsorship</span>
                                                                            )}
                                                                            <span className="badge bg-cust-tag text-cust-d-pur me-2"><i className="bi bi-house"></i> Remote Possible</span>
                                                                        </div>
                                                                        <p className="MuiTypography-root MuiTypography-body2 css-sns183">{job.ShortDescription}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </article>

                                                ))
                                            }
                                        </>

                                    </div>
                                </div>
                                <div className="row align-items-center">
                                    <div className="col-lg-12">
                                        <nav aria-label="Page navigation example">
                                            <ul className="pagination pagination-sm pagination-spaced gap-2 justify-content-end">
                                                <li className={`page-item ${currentPage <= 1 ? 'disabled' : ''}`}>
                                                    <a className="page-link" onClick={() => handlePageChange(Math.max(1, currentPage - 1))}>
                                                        <i className="bi bi-chevron-left"></i>
                                                    </a>
                                                </li>
                                                {renderPagination()}
                                                <li className={`page-item ${currentPage >= totalPages ? 'disabled' : ''}`}>
                                                    <a className="page-link" onClick={() => handlePageChange(Math.min(totalPages, currentPage + 1))}>
                                                        <i className="bi bi-chevron-right"></i>
                                                    </a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Jobs;
