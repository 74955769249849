import React, { useContext, useState,useEffect } from 'react';
import { CandidateContext } from '../../Context/CandidateContext';
import TopNav from '../../pages/Candidate/topNav';
import { useAuth0 } from '@auth0/auth0-react';

function Jobs() {
    const { isAuthenticated } = useAuth0();
    useEffect(() => {
        document.title = "Job Applications | TalentUp";
    }, []);
   
    const { apiCandidateID, apiCandidateJobs } = useContext(CandidateContext);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' }; 
        return new Date(dateString).toLocaleDateString(undefined, options);
    };
    const hasApplications = apiCandidateJobs && apiCandidateJobs.length > 0;
    if (!isAuthenticated) {
        return <div>Please log in to access the dashboard.</div>;
    }
    return (
            <div className="flex-lg-1 h-screen overflow-y-lg-auto profile-lalk_lo">
                <TopNav titleName={'Job Applications'} />
                <main className="py-6 bg-surface-secondary">
                    <div className="container-fluid max-w-screen-xl gap-6">
                    {isLoading ? <p className="loadingStyle">Loading...</p> : error ? <p>Error: {error}</p> :
                            hasApplications ? apiCandidateJobs.map((job) => (
                                <div key={job.AppliedJobID} className="JobApps_par mb-4">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex">
                                                <div className="me-4">
                                                    <div className="avatar rounded-circle avatar-background">
                                                        {job.BusinessName[0].toUpperCase()}
                                                    </div>
                                                </div>

                                                <div className="flex-fill">
                                                    <span className="h4">{job.Title}</span>
                                                    <span className="h6 font-semibold text-muted text-sm d-block mb-2">
                                                        {job.BusinessName}
                                                    </span>
                                                    <span className="badge bg-cust-tag text-cust-d-pur me-2"><i className="bi bi-calendar"></i> Applied: {formatDate(job.ApplyDate)}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )) : (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90vh' }}>
                                <div>
                                    <div className="text-center">
                                        <h1 className="mb-4">Welcome to TalentUp!</h1>
                                        <p>It looks like you haven't found your perfect job match yet. That's okay!</p>
                                        <strong>Here's how to get started:</strong>
                                        <ol className="text-start mx-auto" style={{ maxWidth: '600px' }}>
                                            <li className="my-2">
                                                <strong>Complete Your Profile</strong>: Fill in your profile details thoroughly - from your skills to your employment history. The more complete your profile is, the better employers can understand your strengths and experiences.
                                            </li>
                                            <li className="my-2">
                                                <strong>Explore Job Listings</strong>: Browse through our job listings to find opportunities that match your interests and skills. With new jobs added regularly, there's always a chance to find something that's just right for you.
                                            </li>
                                            <li className="my-2">
                                                <strong>Apply</strong>: When you find a job that excites you, don't hesitate to apply. Tailor your application to highlight why you're the perfect fit for the role.
                                            </li>
                                        </ol>
                                        <p>Remember, every application is a step closer to your next career milestone. If you need help or have any questions, our team is here to support you every step of the way.</p>
                                        <p>Happy job hunting!</p>
                                    </div>
                                </div>
                            </div>
                            )
                        }
                    </div>
                </main>
            </div>
    );
}

export default Jobs;
