import React, { useState, useEffect,useContext } from 'react';
import { Modal, Button } from 'react-bootstrap';
import SkillsForm from './SkillsForm';
import SkillsAddForm from '../../pages/Candidate/SkillsAdd';
import { TokenContext } from '../../Context/TokenContext'; 
import { getApiBaseUrl } from '../../utils/Config';
import { AlertContext } from '../../Context/AlertContext';
import { useAuth0 } from '@auth0/auth0-react';

const CandidateSkills = ({ candidateID, candidateData }) => {
    const { isAuthenticated } = useAuth0();

    const { token, idTokenClaims } = useContext(TokenContext);
    const [editingIndex, setEditingIndex] = useState(null);
    const [showAddForm, setShowAddForm] = useState(false);
    const [skillItems, setSkillItems] = useState(candidateData || []);
    const [skillIdToDelete, setSkillIdToDelete] = useState(null);
    const [deleting, setDeleting] = useState(false);
    const baseUrl = getApiBaseUrl();
    const { showAlert } = useContext(AlertContext);

    const handleEditClick = (index) => {
        setEditingIndex(index);
    };

    const handleCancelClick = () => {
        setEditingIndex(null);
    };
    const handleAddCancelClick = () => {
        setShowAddForm(false);
    };

    const updateSkillItems = (newSkillsItem) => {
        setSkillItems((prevItems) => [...prevItems, newSkillsItem]);
        setShowAddForm(false);

    };
    useEffect(() => {
        if (candidateData) {
            setSkillItems(candidateData);
        }
    }, [candidateData]);

    const handleUpdate = (updatedItem) => {

        setSkillItems(prevItems => prevItems.map(item => item.SkillID === updatedItem.SkillID ? updatedItem : item));
        setEditingIndex(null);
    };
    const handleDelete = async (SkillId) => {
        if (deleting) {
            return;
        }
        setDeleting(true);
        try {
            const response = await fetch(
                `${baseUrl}/api/Skill/Delete?SkillId=${SkillId}`,
                {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const responseData = await response.json();
            if (responseData.success) {
                setSkillItems(prevItems => prevItems.filter(item => item.SkillID !== SkillId));
                showAlert('Item deleted successfully.', true);
            }
            else {
                showAlert('Oops, something went wrong. Please try again later.', false);
            }
        } catch (error) {
            console.error('There was an error deleting the item:', error);
            showAlert('Oops, something went wrong. Please try again later.', false);
        }
        finally {
            setDeleting(false);
            setSkillIdToDelete(null);
        }
    };

    useEffect(() => {
    }, [skillItems]);
    if (!isAuthenticated) {
        return <div>Please log in to access the dashboard.</div>;
    }

    return (
        <>
            {skillItems.map((skill, index) => {
                return (
                    <div className="skill-item" key={skill.SkillID}>
                        {editingIndex === index ? (
                            <SkillsForm
                                candidateData={skill}
                                token={token}
                                onCancel={handleCancelClick}
                                handleUpdate={handleUpdate}
                            />
                        ) : (
                            <div className="bnasdf_asldf skill-item-read">
                                    <div className="card-header d-flex justify-content-between align-items-center">
                                        <div>
                                            <h5 className="d-block h5 font-semibold mb-1">{skill.Name} </h5><h3 className="text-sm text-muted"><span className="yearsExp">{skill.YearsExperience}</span> Years Experience</h3>
                                        </div>

                                    <div className="text-end mx-n2 d-flex align-items-center">
                                        <button type="button" className="btn me-2-15 edit-skill edit-but" onClick={() => handleEditClick(index)}></button>
                                        <button className="btn me-2-15 delete-but" data-bs-target="#deleteskillConfirm" onClick={() => setSkillIdToDelete(skill.SkillID)}></button>
                                    </div>
                                </div>
                            </div>

                        )}
                    </div>
                );
            })}
            {showAddForm && (
                <SkillsAddForm
                    candidateID={candidateID}
                    token={token}
                    onCancel={handleAddCancelClick}
                    updateSkillItems={updateSkillItems}
                />
            )}
            <div className="text-center skill-create-kksj">
                <button className="btn btn-primary profile-button-w200" onClick={() => setShowAddForm(true)}><i className="bi bi-plus"></i></button>
            </div>

            <Modal show={skillIdToDelete !== null} onHide={() => setSkillIdToDelete(null)} centered>
                <Modal.Header>
                    <div className="icon icon-shape rounded-3 bg-soft-primary text-danger text-lg me-4">
                        <i className="bi bi-trash"></i>
                    </div>
                    <Modal.Title>Delete, Are you sure?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => handleDelete(skillIdToDelete)} disabled={deleting}>
                        {deleting ? 'Deleting...' : 'Delete Now'}
                    </Button>
                    <Button variant="neutral" onClick={() => setSkillIdToDelete(null)}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>

    );

}

export default CandidateSkills;
