// utils/SkeletonLoader.js
import React from 'react';
import '../css/talentup.css';

const SkeletonLoader = ({ type }) => {
    switch (type) {
        case 'singleLine':
            return <div className="skeleton-line"></div>;
        case 'ProfileProgress':
            return <div className="skeleton-loader-profile">
                <div className="skeleton-line-profile"></div>
                <div className="skeleton-line-profile"></div>
                <div className="skeleton-line-profile"></div>
                <div className="skeleton-line-profile"></div>
                <div className="skeleton-line-profile"></div>
            </div>;
        case 'bigBox':
            return <div className="skeleton-big-box"></div>;
        case 'JobList':
            return (
                <div className="container-xxl mb-7">
                    {[...Array(5)].map((_, index) => (
                        <div key={index} className="vstack gap-6 mb-3">
                            <div className="card">
                                <div className="card-body">
                                    <div className="skeleton-loader">
                                        <div className="skeleton-header"></div>
                                        <div className="skeleton-line"></div>
                                        <div className="skeleton-line"></div>
                                        <div className="skeleton-line"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            );
        default:
            return (
                <div className="skeleton-loader">
                    <div className="skeleton-header"></div>
                    <div className="skeleton-line"></div>
                    <div className="skeleton-line"></div>
                    <div className="skeleton-line"></div>
                </div>
            );
    }
};

export default SkeletonLoader;
