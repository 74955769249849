// Header.js
import React from 'react';
import logoImage from '../../src/images/Logo1-square2-white-transparent.png';

function NotFound() {
    return (
        <p>lol, its gone.</p>
    );
}

export default NotFound;
