import React, { useState, useEffect, useContext } from 'react';
import { TokenContext } from '../../Context/TokenContext';
import { getApiBaseUrl } from '../../utils/Config';
import { useAuth0 } from '@auth0/auth0-react';

const CandidateBusPersonalDetails = ({ candidateDetailsmore, candidateDatadetails }) => {
    const { isAuthenticated } = useAuth0();
    const mobileNumber = candidateDatadetails.MobileNumber || 'Not available';
    const { token, idTokenClaims } = useContext(TokenContext);
    const email = candidateDatadetails.Email || 'Not available';
    const location = candidateDetailsmore.Location || 'Not available';
    const cstatus = candidateDetailsmore.Citizenship || 'Not available';
    const [countryCode, setCountryCodeResult] = useState(false);
    const contcodeid = candidateDatadetails.CountryCodeID || 0;
    const baseUrl = getApiBaseUrl();

    const mapCitizenshipToText = (value) => {
        switch (value) {
            case 1: return "Australian citizen";
            case 2: return "Permanent Resident";
            case 3: return "Family/partner visa with no restrictions"
            case 4: return "Seeking Sponsorship"
            case 5: return "Holiday temporary work visa"
            case 6: return "Temporary visa with no restrictions"
            case 7: return "Temporary visa with restrictions"
            default: return "";
        }
    };
    useEffect(() => {
        const getCountryCode = async () => {
            if (token) {
                try {
                    const response = await fetch(
                        `${baseUrl}/api/LookUp/GetCountryCodeText?countryCodeId=${contcodeid}`,
                        {
                            mode: 'cors',
                            headers: {
                                "Authorization": `Bearer ${token}`
                            }
                        }
                    );

                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    const data = await response.json();
                    setCountryCodeResult(data.result);

                } catch (error) {
                } 
            }
        };
        getCountryCode();
    }, [token, idTokenClaims]);


    if (!isAuthenticated) {
        return <div>Please log in to access the dashboard.</div>;
    }
 
    return (

        <div className="hsf_bv_dkjoows">
            <div className="pss_sdl_dkjoows">
                <div className="">
                    <div className="dgfsdfgsdfg">
                        {candidateDatadetails.MobileNumber && (
                            <p className="personInfo_mn">
                                <i className="bi bi-phone"></i> {countryCode.Code} {mobileNumber}
                            </p>
                        )}
                       

                        <p className="personInfo_em">
                            <i className="bi bi-envelope"></i>  {email}
                        </p>

                        <p className="personInfo_em">
                            <i className="bi bi-geo-alt"></i>  {location}
                        </p>
                        <p className="Pdetails_czs">
                            <i className="bi bi-map"></i> <span className="title-fleek"></span> {mapCitizenshipToText(cstatus)}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CandidateBusPersonalDetails;
