import React, { useState, useEffect } from 'react';
import SkeletonLoader from '../../utils/SkeletonLoader';
import { useAuth0 } from '@auth0/auth0-react';

const CandidateBusExperience = ({ candidateData }) => {
    const { isAuthenticated } = useAuth0();
    const [experienceItems, setExperienceItems] = useState(candidateData || []);
    const [hasCurrent, setHasCurrent] = useState(false);
  
    useEffect(() => {
        if (candidateData) {
            setExperienceItems(candidateData);
        }
    }, [candidateData]);

   
    useEffect(() => {
        if (candidateData) {
            setExperienceItems(candidateData);
            // Check if any experience is current and update hasCurrent state accordingly
            const currentExists = candidateData.some(exp => exp.Current);
            setHasCurrent(currentExists);
        }
    }, [candidateData]);

    const handleShowMoreExperience = (experienceId) => {
        const detailsElement = document.querySelector(`.exp-${experienceId}-toggle-details`);
        if (detailsElement.style.display === 'none') {
            detailsElement.style.display = 'block';
        } else {
            detailsElement.style.display = 'none';
        }
    };

    useEffect(() => {
    }, [experienceItems]);

    if (!isAuthenticated) {
        return <div>Please log in to access the dashboard.</div>;
    }
    if (!candidateData) {
        return <SkeletonLoader />;
    }
    return (
        <>
            {experienceItems.map((experience, index) => {
                return (
                    <div className="experience-item" key={experience.ExperienceID}>
                            <div className="bnasdf_asldf experience-item-read">
                            <div className="card-body car-dlj-ds d-flex justify-content-between align-items-center">
                                    <div>
                                        {experience.Current && (
                                            <div className={`currentRoleContainer-${index}`}>
                                                <div className="MuiBox-root current-Title-la">
                                                    <span className="badge bg-success text-success exp-current-flag" style={{ verticalAlign: 'top' }}>Current Role</span>
                                                </div>
                                            </div>
                                        )}

                                        <div className={`Exp-Title`}>
                                            <h5 className="d-block h5 font-semibold mb-1">{experience.Title}</h5>
                                        </div>
                                        <h5 className="d-block h5 mb-1 h5company" style={{ fontWeight: 700 }}>{experience.Company}</h5>

                                        {experience.Current ? (
                                            <h3 className="d-block text-sm text-muted">
                                                {new Date(experience.StartDate).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit' })} to Current
                                            </h3>
                                        ) : (
                                            <h3 className="d-block text-sm text-muted">
                                                {new Date(experience.StartDate).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit' })} to {new Date(experience.EndDate).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit' })}
                                            </h3>
                                        )}

                                        {!experience.About ? null : (
                                            <>
                                                <button className="btn btn-inner-hidden as_ksk_iu bi-chevron-down" onClick={() => handleShowMoreExperience(experience.ExperienceID)} data-expid={experience.ExperienceID}>More</button>
                                                <div className={`experience-show-details exp-${experience.ExperienceID}-toggle-details ${index}-exp-item-sd`} style={{ display: 'none' }}>
                                                    {experience.About}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                    </div>
                );
            })}
        </>
    );

}

export default CandidateBusExperience;
