import React, { useContext } from 'react';
import SkeletonLoader from '../../utils/SkeletonLoader';
import { CandidateContext } from '../../Context/CandidateContext';

const ProfileItem = ({ data, label }) => {
    const hasData = data && (Array.isArray(data) ? data.length > 0 : true);
    const className = hasData ? 'pp_done' : 'pp_tobedone';

    return (
        <span className={className}>
            <i className="bi-check-circle-fill"></i> {label}
        </span>
    );
}

const ProfileProgress = () => {
    const {
        apiDataPreferences,
        apiDataSummary,
        apiDataSocial,
        apiDataExperience,
        apiDataEducation,
        apiDataSkill,
        apiCandidateResume,
        isLoading
    } = useContext(CandidateContext);

    if (isLoading) {
        return <SkeletonLoader type="ProfileProgress" />;
    }

    return (
        <div className="ProfilePPAr">
            <h4>Profile Progress</h4>
            <ProfileItem data={apiDataPreferences} label="Personal Preferences" />
            <ProfileItem data={apiDataSummary} label="Your Summary" />
            <ProfileItem data={apiDataSocial} label="Social Accounts" />
            <ProfileItem data={apiDataEducation} label="Education History" />
            <ProfileItem data={apiDataExperience} label="Work Experience" />
            <ProfileItem data={apiDataSkill} label="Skills" />
            <ProfileItem data={apiCandidateResume} label="Resume" />
        </div>
    );
}

export default ProfileProgress;
