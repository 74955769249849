import React, { useReducer, useEffect, createContext, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const TokenContext = createContext();

const authReducer = (state, action) => {
    switch (action.type) {
        case 'FETCH_SUCCESS':
            return { ...state, loading: false, token: action.token, idTokenClaims: action.idTokenClaims, oid: action.oid, email: action.email, error: null };
        case 'FETCH_ERROR':
            return { ...state, loading: false, error: action.error.message };
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};

export const TokenProvider = ({ children }) => {
    console.log("token context started")
    const { getAccessTokenSilently, user, isAuthenticated, loginWithRedirect, logout } = useAuth0();

    const [state, dispatch] = useReducer(authReducer, {
        token: null,
        idTokenClaims: null,
        loading: true,
        error: null,
        oid: null,
        email: null,
    });

    const fetchToken = async () => {
        if (!isAuthenticated) return;

        try {
            const token = await getAccessTokenSilently({
                authorizationParams: {
                    audience: `https://talentup-uat-api.azurewebsites.net`,
                    scope: "candidate.read email",
                },
            });
            dispatch({
                type: 'FETCH_SUCCESS',
                token,
                idTokenClaims: user,
                oid: user.sub,
                email: user.email,
            });
            console.log("token context", token)
            console.log("token context", user.sub)
        } catch (error) {
            console.error('Failed to acquire token:', error);
            dispatch({
                type: 'FETCH_ERROR',
                error: error,
            });
        }
    };

    useEffect(() => {
        if (isAuthenticated) {
            fetchToken();
        }

        const intervalId = setInterval(() => {
            fetchToken();
        }, 60000); // Checks every minute
        return () => clearInterval(intervalId);
    }, [isAuthenticated]);

    //we need to handle tokens expiring or no access to api here


    return (
        <TokenContext.Provider value={state}>
            {children}
        </TokenContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(TokenContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within a TokenProvider');
    }
    return context;
};

export { TokenContext };
