import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
    const { isAuthenticated, loginWithRedirect } = useAuth0();
    const location = useLocation();
    React.useEffect(() => {
        if (!isAuthenticated) {
            const handleLoginRedirect = async () => {
                try {
                    console.log("ProtectedRoute: User not authenticated. Redirecting to login...");
                   
                    await loginWithRedirect({
                        appState: { targetUrl: location.pathname },
                        authorizationParams: {
                            redirect_uri: `${window.location.origin}/auth/callback`,
                        },
                    });


                    console.log("ProtectedRoute: Login redirect initiated with appState:");
                } catch (error) {
                    console.error("ProtectedRoute: Error during login redirect:", error);
                }
            };
            handleLoginRedirect();
        }
    }, [isAuthenticated, loginWithRedirect, location]);

    if (!isAuthenticated) {
        return <div className="loadingStyle">Redirecting...</div>;
    }

    console.log("ProtectedRoute: User authenticated. Rendering children.");
    return children;
};

export default ProtectedRoute;
