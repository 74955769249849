import React, { useState, useEffect,useContext } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useToken } from '../../utils/getToken';
import SocialForm from '../../pages/Candidate/SocialForm';
import SocialAddForm from '../../pages/Candidate/SocialAdd';
import { TokenContext } from '../../Context/TokenContext'; 
import { getApiBaseUrl } from '../../utils/Config';
import { AlertContext } from '../../Context/AlertContext';
import { useAuth0 } from '@auth0/auth0-react';

const CandidateSocials = ({ candidateID, candidateData }) => {
    const { isAuthenticated } = useAuth0();
 
    const { token, idTokenClaims } = useContext(TokenContext);
    const [editingIndex, setEditingIndex] = useState(null);
    const [showAddForm, setShowAddForm] = useState(false);
    const [socialItems, setSocialItems] = useState(candidateData || []);
    const [socialIdToDelete, setSocialIdToDelete] = useState(null);
    const [deleting, setDeleting] = useState(false);
    const baseUrl = getApiBaseUrl();
    const { showAlert } = useContext(AlertContext);

    const handleEditClick = (index) => {
        setEditingIndex(index);
    };
    const handleCancelClick = () => {
        setEditingIndex(null);
    };
    const handleAddCancelClick = () => {
        setShowAddForm(false);
    };

    const updateSocialItems = (newSocialItem) => {
        setSocialItems((prevItems) => [...prevItems, newSocialItem]);
        setShowAddForm(false);

    };
    useEffect(() => {
        if (candidateData) {
            setSocialItems(candidateData);
        }
    }, [candidateData]);

    const handleUpdate = (updatedItem) => {

        setSocialItems(prevItems => prevItems.map(item => item.SocialID === updatedItem.SocialID ? updatedItem : item));
        setEditingIndex(null); 
    };
    const handleDelete = async (socialId) => {
        if (deleting) {
            return;
        }
        setDeleting(true);
        try {
            const response = await fetch(
                `${baseUrl}/api/Social/Delete?socialId=${socialId}`,
                {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const responseData = await response.json();
            if (responseData.success) {
                setSocialItems(prevItems => prevItems.filter(item => item.SocialID !== socialId));
                showAlert('Item deleted successfully.', true);
            }
            else {
                showAlert('Oops, something went wrong. Please try again later.', false);
            }
        } catch (error) {
            showAlert('Oops, something went wrong. Please try again later.', false);
        }
        finally {
            setDeleting(false);
            setSocialIdToDelete(null);
        }
    };
 
    useEffect(() => {
    }, [socialItems]);
    if (!isAuthenticated) {
        return <div>Please log in to access the dashboard.</div>;
    }

    return (
        <>
            {socialItems.map((social, index) => {
                return (
                    <div className="social-item" key={social.SocialID}>
                        {editingIndex === index ? (
                            <SocialForm
                                candidateID={candidateID}
                                candidateData={social}
                                token={token}
                                onCancel={handleCancelClick}
                                handleUpdate={handleUpdate}
                            />
                        ) : (
                            <div className="bnasdf_asldf social-item-read">
                                <div className="card-header d-flex justify-content-between align-items-center">
                                    <div>
                                        <span className="d-inline-block me-3">
                                            <i className={`bi bi-${social.SocialName.toString().toLowerCase()}`}></i>
                                        </span>
                                        {social.Link}
                                    </div>
                                    <div className="text-end mx-n2 d-flex align-items-center">
                                        <button
                                            type="button"
                                            className="btn me-2-15 edit-social edit-but"
                                            onClick={() => handleEditClick(index)}
                                        >
                                        </button>
                                        <button
                                            className="btn me-2-15 delete-but"
                                            data-bs-target="#deletesocConfirm"
                                            onClick={() => setSocialIdToDelete(social.SocialID)}
                                        ></button>                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                );
            })}
            {showAddForm && (
                <SocialAddForm
                    candidateID={candidateID}
                    token={token}
                    onCancel={handleAddCancelClick}
                    updateSocialItems={updateSocialItems}
                />
            )}
            <div className="text-center social-create-kksj">
                <button className="btn btn-primary profile-button-w200" onClick={() => setShowAddForm(true)}><i className="bi bi-plus"></i></button>
            </div>

            <Modal show={socialIdToDelete !== null} onHide={() => setSocialIdToDelete(null)} centered>
                <Modal.Header>
                    <div className="icon icon-shape rounded-3 bg-soft-primary text-danger text-lg me-4">
                        <i className="bi bi-trash"></i>
                    </div>
                    <Modal.Title>Delete, Are you sure?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => handleDelete(socialIdToDelete)} disabled={deleting}>
                        {deleting ? 'Deleting...' : 'Delete Now'}
                    </Button>
                    <Button variant="neutral" onClick={() => setSocialIdToDelete(null)}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>

    );

}

export default CandidateSocials;
