import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

function JobSearch() {
    useEffect(() => {
        document.title = "Job Search | TalentUp.com.au";
    }, []);
    const [showSalaryOptions, setShowSalaryOptions] = useState(false);
    const [selectedSalaries, setSelectedSalaries] = useState([]);
    const [showEmploymentOptions, setShowEmploymentOptions] = useState(false);
    const [selectedEmployment, setSelectedEmployment] = useState([]);
    // Salary options to display
    const salaryOptions = [
        { label: '$0 - $50,000', value: '0-50000' },
        { label: '$50,001 - $100,000', value: '50001-100000' },
        { label: '$100,001 - $150,000', value: '100001-150000' },
        { label: '$150,001+', value: '150001+' },
    ];
    const [activeDropdown, setActiveDropdown] = useState(null);

    const toggleDropdown = (dropdownName) => {
        if (activeDropdown === dropdownName) {
            setActiveDropdown(null); // Hide the current dropdown
        } else {
            setActiveDropdown(dropdownName); // Show the clicked dropdown
        }
    };
    const employmentOptions = [
        { label: 'Full-Time', value: 'Full-Time' },
        { label: 'Part-Time', value: 'Part-Time' },
        { label: 'Casual', value: 'Casual' },
        { label: 'Contract', value: 'Contract' },
        { label: 'Temporary', value: 'Temporary' },
        { label: 'Freelance', value: 'Freelance' },
        { label: 'Internship', value: 'Internship' },
        { label: 'Consultancy', value: 'Consultancy' },
        { label: 'Apprenticeship', value: 'Apprenticeship' },
    ];
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState({
        what: searchParams.get('what') || '',
        where: searchParams.get('where') || '',
        pageNumber: searchParams.get('pageNumber') || ''
    });
    const [showMoreOptions, setShowMoreOptions] = useState(false);
    const handleShowMoreOptions = () => {
        setShowMoreOptions(!showMoreOptions);
    };
    const handleSalaryOptionChange = (event) => {
        const value = event.target.value;
        if (event.target.checked) {
            // Add the salary option
            setSelectedSalaries([...selectedSalaries, value]);
        } else {
            // Remove the salary option
            setSelectedSalaries(selectedSalaries.filter((salary) => salary !== value));
        }
    };
    const handleEmploymentOptionChange = (event) => {
        const value = event.target.value;
        if (event.target.checked) {
            setSelectedEmployment([...selectedEmployment, value]);
        } else {
            setSelectedEmployment(selectedEmployment.filter((employment) => employment !== value));
        }
    };
    useEffect(() => {
        // Update searchQuery when searchParams change
        setSearchQuery({
            what: searchParams.get('what') || '',
            where: searchParams.get('where') || '',
            pageNumber: searchParams.get('pageNumber') || ''
        });
    }, [searchParams]);

    const handleInputChange = (event) => {
        setSearchQuery({ ...searchQuery, [event.target.name]: event.target.value });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // Update the URL with new search parameters
        const newSearchParams = {
            what: searchQuery.what,
            where: searchQuery.where,
            pageNumber: '1'
        };
        setSearchParams(newSearchParams);
        navigate(`/jobs?what=${encodeURIComponent(searchQuery.what)}&where=${encodeURIComponent(searchQuery.where)}&pageNumber=1`);
    };

    return (
        <>
            <div className="search-section-desk">
                <div className="search-section-inner-grb">
                    <div className="container-fluid container-index-w234">
                        <div className="search-section-vt">
                            <h1 className="search-section-hd css-sc-hd">
                                Find <span className="css-sc-hd_21">tech</span> job opportunities in Australia
                            </h1>
                            <div className="search-section-sb-outer">
                                <form className="search-section-vt-form-al">
                                    <div className="search-section-vt-form-int pasdk_slk">
                                        <div className="search-section-vt-form-what">
                                            <div className="input-group input-group-lg input-group-inline mb-1">
                                                <span className="input-group-text fontWeightS">What</span>
                                                <input type="text" className="form-control" placeholder="Job Title or Keyword" name="what" value={searchQuery.what} onChange={handleInputChange}></input>
                                            </div>
                                        </div>
                                        <div className="search-section-vt-form-where">
                                            <div className="input-group input-group-lg input-group-inline mb-1">
                                                <span className="input-group-text fontWeightS">Where </span>
                                                <input type="text" className="form-control" placeholder="Location" name="where" value={searchQuery.where} onChange={handleInputChange}></input>
                                            </div>
                                        </div>
                                        <div className="search-section-vt-form-button">
                                            <button type="submit" className="btn btn-primary btn-lg" onClick={handleSubmit}>
                                                <span className="ssri-subbut-inner-span ssri-subbut-inner-sub"><i className="bi bi-search"></i></span>
                                            </button>
                                        </div>
                                    </div>

                                    <div className="filer_more_op">
                                        <div className="row align-items-center">
                                            <div className="col-sm col-12">
                                                {showMoreOptions && (
                                                    <div className="filer_sub_buttons">
                                                        <button
                                                            type="button"
                                                            className="btn btn-sm btn-neutral border-base me-2"
                                                            onClick={() => toggleDropdown('employment')}
                                                        >
                                                            <span>Employment Type</span>
                                                        </button>
                                                        {activeDropdown === 'employment' && (
                                                            <div className="dropdown-menu d-block">
                                                                {employmentOptions.map((option) => (
                                                                    <div key={option.value} className="form-check">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            value={option.value}
                                                                            id={`employment-option-${option.value}`}
                                                                            onChange={handleEmploymentOptionChange}
                                                                            checked={selectedEmployment.includes(option.value)}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={`salary-option-${option.value}`}>
                                                                            {option.label}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}
                                                        <button
                                                            type="button"
                                                            className="btn btn-sm btn-neutral border-base me-2"
                                                            onClick={() => toggleDropdown('salary')}
                                                        >
                                                            <span>Salary</span>
                                                        </button>
                                                        {activeDropdown === 'salary' && (
                                                            <div className="dropdown-menu d-block">
                                                                {salaryOptions.map((option) => (
                                                                    <div key={option.value} className="form-check">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            value={option.value}
                                                                            id={`salary-option-${option.value}`}
                                                                            onChange={handleSalaryOptionChange}
                                                                            checked={selectedSalaries.includes(option.value)}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={`salary-option-${option.value}`}>
                                                                            {option.label}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}
                                                        <button type="submit" className="btn btn-sm btn-neutral border-base me-2">
                                                            <span>Sponsorships</span>
                                                        </button>
                                                        <button type="submit" className="btn btn-sm btn-neutral border-base me-2">
                                                            <span>Remote</span>
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-sm-auto col-12 mt-4 mt-sm-0">
                                                {!showMoreOptions && (
                                                    <div className="hstack gap-2 justify-content-sm-end filer_sub_toggle">
                                                        <button type="submit" className="btn btn-sm btn-neutral border-base" onClick={handleShowMoreOptions}>
                                                            <span>Show more options</span>
                                                            <span className="pe-2"><i className="bi bi-sliders rotate-icon"></i> </span>
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default JobSearch;
