import React from "react";
import { useAuth0 } from '@auth0/auth0-react';
import logo from '../images/logohome.png';
import { Link } from 'react-router-dom';

function HomeHeader() {
    const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();

    const handleLogin = () => {
        loginWithRedirect({
            redirectUri: `${window.location.origin}/auth/callback`,
            authorizationParams: {
                userType: 'candidate',
                audience: `https://talentup-uat-api.azurewebsites.net`,
                scope: "candidate.read email",
            },

        });
    };

    const handleLogout = () => {
        logout({ returnTo: window.location.origin });
    };

    return (
        <header>
            <nav className="navbar navbar-expand-sm navbar-toggleable-sm navbar-light bg-white border-bottom box-shadow">
                <div className="container-fluid  container-index-w234">
                    <Link className="navbar-brand" to="/">
                        <img src={logo} className="csshomelogo" alt="talentup logo"></img>
                    </Link>

                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target=".navbar-collapse" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="navbar-collapse collapse d-sm-inline-flex justify-content-between">
                        <div className="navbar-tu-inn ms-auto">
                            <ul className="navbar-nav flex-grow-1">
                                <li className="nav-item">
                                    <Link className="nav-link text-dark" to="/">Find Jobs</Link>
                                </li>

                                {isAuthenticated ? (
                                    <>
                                        <li className="nav-item">
                                            <Link className="nav-link text-dark" to="/candidate/profile">Profile</Link>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link text-dark" href="#" onClick={handleLogout}>Log Out</a>
                                        </li>
                                    </>
                                ) : (
                                    <li className="nav-item">
                                        <a className="nav-link text-dark" href="#" onClick={handleLogin}>Sign In</a>
                                    </li>
                                )}
                                <li className="nav-item">
                                    <a className="nav-link text-dark" href="https://business.talentup.com.au">Employers / Post Job</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    );
}

export default HomeHeader;
