import React, { useCallback, useState,useContext} from 'react';
import { useDropzone } from 'react-dropzone';
import { TokenContext } from '../../Context/TokenContext'; 
import { getApiBaseUrl } from '../../utils/Config';
import { AlertContext } from '../../Context/AlertContext';
import { useAuth0 } from '@auth0/auth0-react';

function CertificateAddForm({ candidateData, updateCertificateItems, candidateID, onCancel }) {
    const { isAuthenticated } = useAuth0();
  
    const { token, idTokenClaims } = useContext(TokenContext);
    const [saving, setSaving] = useState(false);
    const [file, setFile] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const { showAlert } = useContext(AlertContext);

    const onFileChange = useCallback((acceptedFiles, fileRejections) => {
        if (acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
            setSelectedFile(file);
            const objectURL = URL.createObjectURL(file);
        }

        if (fileRejections.length > 0) {
            fileRejections.forEach(rejection => {
                if (rejection.errors.some(error => error.code === 'file-too-large')) {
                    showAlert(`File "${rejection.file.name}" is too large.`, false);
                } else if (rejection.errors.some(error => error.code === 'file-invalid-type')) {
                    showAlert(`File "${rejection.file.name}" has an invalid type.`, false);
                } else {
                    showAlert(`File "${rejection.file.name}" was rejected.`, false);
                }
            });
        }
    }, []);
    const baseUrl = getApiBaseUrl();


    const { getRootProps, getInputProps } = useDropzone({
        onDrop: onFileChange,
        accept: {
            'application/pdf': ['.pdf'],
            'application/msword': ['.doc'],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx']
        },
        multiple: false,
        maxSize: 2 * 1024 * 1024  // Limit to 2MB
    });
    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                resolve(reader.result.split(",")[1]);
            };
            reader.onerror = error => {
                console.error("Error while reading the file:", error);
                reject(error);
            };
        });
    }

    //Submit to API
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!selectedFile) {
            showAlert('Please select a file before submitting', false);
            return;
        }
        setSaving(true);

        const base64File = await getBase64(selectedFile);
        const payload = {
            FIleBase64: base64File,
            CandidateID: candidateID,
            DateTimeCreated: new Date().toISOString(),
            DateTimeModified: new Date().toISOString(),
            CreatedBy: 'Admin',
            ModifiedBy: 'User',
            IsDeleted: false,
            B2CObjectId: idTokenClaims.oid,
            AttachmentTypeId: 4,
            FileName: selectedFile.name
        };
        try {
            const response = await fetch(
                `${baseUrl}/api/Attachment/UploadCandidateAttachment`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(payload),
                }
            );
            const responseData = await response.json();

            if (responseData.success) {
                showAlert('Success', true);
                updateCertificateItems(responseData.result);

            } else {
                const data = await response.json();
                showAlert(data.message || 'Oops, something went wrong. Please try again later.', false);
            }
        }
        catch (error) {
            showAlert('Oops, something went wrong. Please try again later.', false);
        }
        finally {
            setSaving(false);
        }
    };
    if (!isAuthenticated) {
        return <div>Please log in to access the dashboard.</div>;
    }

    return (
        <div className="certificate-create-form">
            <form id="certificate-add-form" onSubmit={handleSubmit}>
                <div className="certificate-item-edit">
                    <div className="certificate-edit card-body-iajj">
                        <input type="hidden" className="form-control" value={candidateID} name="candidateID" readOnly />
                        <div className="row g-5">
                            <div>
                                <div className="rounded border-2 border-dashed border-primary-hover position-relative" {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <div className="d-flex justify-content-center px-5 py-5">
                                        <div className="text-center">
                                            <div className="d-flex text-sm mt-3">
                                                <p className="font-semibold">Click or drag here to upload your Certificate</p>
                                            </div>
                                            <p className="text-xs text-gray-500">
                                                PDF and WORD Docs up to 2MB
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div id="upload-box-detail" className="list-group list-group-flush mt-2">
                                    {selectedFile ? (
                                        <div className="file-details">
                                            <span className="file-name">{selectedFile.name}</span>
                                            {/* You can also display other details like file size, type, etc. if needed */}
                                        </div>
                                    ) : (
                                        <div className="no-file-selected">
                                            No file selected.
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="col-12 text-end">
                                <button type="submit" className="btn btn-sm me-2 btn-primary" disabled={saving}>{saving ? 'Saving...' : 'Save'}</button>
                                <button type="button" className="btn btn-sm btn-neutral me-2" onClick={onCancel}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default CertificateAddForm;
