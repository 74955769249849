import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { auth0Config } from '../utils/auth0Config';

const Auth0ProviderWithHistory = ({ children }) => {
    const navigate = useNavigate();

    const onRedirectCallback = (appState) => {
        const callbackUrl = appState?.targetUrl
            ? `/auth/callback?restate=${encodeURIComponent(appState.targetUrl)}`
            : '/auth/callback';

        navigate(callbackUrl);
    };


    return (
        <Auth0Provider
            domain={auth0Config.domain}
            clientId={auth0Config.clientId}
            authorizationParams={{
                redirect_uri: `${window.location.origin}/auth/callback`,
                audience: auth0Config.audience,
                scope: 'candidate.read',
            }}
            onRedirectCallback={onRedirectCallback}
        >
            {children}
        </Auth0Provider>
    );
};

export default Auth0ProviderWithHistory;
