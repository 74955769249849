import React, { useState, useEffect } from 'react';

const Alert = ({ message, success }) => {
    const [visible, setVisible] = useState(true);
    const alertClassName = `alert ${success ? 'alert-success text-success' : 'alert-warning text-info'}`;

    useEffect(() => {
        setVisible(true);
        if (visible) {
            setTimeout(() => {
                setVisible(false);
            }, 8000);
        }
    }, [visible]);

    if (!visible) {
        return null;
    }

    return (
        <div className="alert-wrapper-below">
            <div className={alertClassName} role="alert">
                {message}
            </div>
        </div>
    );
};

export default Alert;
