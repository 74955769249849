import React, { useState, useEffect, useContext } from 'react';
import { TokenContext } from '../../Context/TokenContext';
import { getApiBaseUrl } from '../../utils/Config';
import { AlertContext } from '../../Context/AlertContext';
import { useAuth0 } from '@auth0/auth0-react';

const CandidatePreferences = ({ candidateData, candidateID }) => {
    const { isAuthenticated } = useAuth0();
    const { token, idTokenClaims } = useContext(TokenContext);
    const [editing, setEditing] = useState(false);
    const [saving, setSaving] = useState(false);
    const baseUrl = getApiBaseUrl();
    const { showAlert } = useContext(AlertContext);

    const handleEditClick = () => {
        setEditing(true);
    };

    const handleCancelClick = () => {
        setEditing(false);
    };
    const [formData, setFormData] = useState({
        DesiredOccupation: "",
        DesiredLocation: "",
        SalaryRange: "",
        IsSponsorship: false,
        PreferenceID: 0,
    });
    const [errors, setErrors] = useState({
        DesiredOccupation: "",
        DesiredLocation: "",
        SalaryRange: "",
        IsSponsorship: "",
    });
    useEffect(() => {
        if (candidateData) {
            setFormData({
                DesiredOccupation: candidateData.DesiredOccupation || "",
                DesiredLocation: candidateData.DesiredLocation || "",
                SalaryRange: candidateData.SalaryRange || 0,
                IsSponsorship: candidateData.IsSponsorship || false,
                PreferenceID: candidateData.PreferenceID,
            });
            setEditing(false);
        } else if (candidateData === null) {
            setEditing(true);
        }
    }, [candidateData]);
    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === "checkbox") {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: checked,
            }));
        } else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value,
            }));
        }
    };
    function getSalaryRangeText(salaryRangeValue) {
        const salaryRanges = {
            1: "Under $30,000",
            2: "$30,000 - $50,000",
            3: "$50,000 - $70,000",
            4: "$70,000 - $90,000",
            5: "$90,000 - $110,000",
            6: "$110,000 - $130,000",
            7: "$130,000 - $150,000",
            8: "$150,000 - $170,000",
            9: "$170,000 - $190,000",
            10: "$190,000 - $210,000",
            11: "$210,000 - $230,000",
            12: "$230,000 - $250,000",
            13: "$250,000 and above",
            14: "Negotiable",
            15: "Competitive",
        };

        return salaryRanges[salaryRangeValue] || "Not specified";
    }


    //Validation
    const validate = () => {
        const newErrors = {
            DesiredOccupation: formData.DesiredOccupation ? (
                /[^a-zA-Z0-9\s]/.test(formData.DesiredOccupation)
                    ? 'Current Role should not have special characters.'
                    : (formData.DesiredOccupation.length > 100)
                        ? 'Current Role should not exceed 100 characters.'
                        : ''
            ) : '',

            IsSponsorship: (formData.IsSponsorship !== '' && typeof formData.IsSponsorship !== 'boolean')
                ? 'Please select a valid Sponsorship option.'
                : '',
        };

        setErrors(newErrors);
        return Object.values(newErrors).every(error => !error);
    };


    //Submit to API
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validate()) {
            if (saving) {
                return;
            }
            setSaving(true);
            const dataToPost = {
                DesiredOccupation: formData.DesiredOccupation,
                DesiredLocation: formData.DesiredLocation,
                SalaryRange: formData.SalaryRange,
                IsSponsorship: formData.IsSponsorship,
                // Set other properties here
                CandidateID: candidateID,
                PreferenceID: formData.PreferenceID,
                DateTimeCreated: new Date().toISOString(),
                DateTimeModified: new Date().toISOString(),
                CreatedBy: 'Admin',
                ModifiedBy: 'User',
                IsDeleted: false,
                B2CObjectId: idTokenClaims.oid,
                Status: true
            };


            try {
                const response = await fetch(
                    `${baseUrl}/api/Preference/Upsert`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                        body: JSON.stringify(dataToPost),
                    }
                );
                const responseData = await response.json();

                if (responseData.success) {
                    const updatedCandidatePrefID = responseData.result.PreferenceID;

                    setFormData(prevFormData => ({
                        ...prevFormData,
                        PreferenceID: updatedCandidatePrefID,
                    }));
                    showAlert('Success', true);
                  /*  setEditing(false);*/
                } else {
                    showAlert('Oops, something went wrong. Please try again later.', false);
                }
            }
            catch (error) {
                showAlert('Oops, something went wrong. Please try again later.', false);
            }
            finally {
                setSaving(false);
            }
        }
    };

    if (!isAuthenticated) {
        return <div>Please log in to access the dashboard.</div>;
    }

    return (
        <div className="pref-item">
            {!editing && (
                <div className="edit-icon">
                    <button type="button" className="btn px-2 edit-pref edit-but" onClick={handleEditClick}></button>
                </div>
            )}
            {editing ? (
                <form id="pref-form">
                    <div className={`pref-item${editing ? 'edit' : 'read'}`}>
                        <div className="pref-edit card-body-iajj">
                            <input type="hidden" className="form-control" defaultValue={formData.PreferenceID} name="PreferenceID" readOnly></input>
                            <input type="hidden" className="form-control" defaultValue={candidateID} name="candidateID" readOnly></input>
                            <div className="row g-5">
                                <div className="col-md-6">
                                    <div>
                                        <label className="form-label">Desired Role</label>
                                        <input type="text" className="form-control" value={formData.DesiredOccupation} name="DesiredOccupation" onChange={handleInputChange} placeholder="Software Dev Manager"></input>
                                        {errors.DesiredOccupation && (
                                            <small className="text-danger">{errors.DesiredOccupation}</small>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div>
                                        <label className="form-label">Desired Location</label>
                                        <select className="form-select" value={formData.DesiredLocation} name="DesiredLocation" id="DesiredLocationSelect" onChange={handleInputChange}>
                                            <option value="">--Select--</option>
                                            <option value="Australian Capital Territory (ACT)">Australian Capital Territory (ACT)</option>
                                            <option value="New South Wales (NSW)">New South Wales (NSW)</option>
                                            <option value="Northern Territory (NT)">Northern Territory (NT)</option>
                                            <option value="Queensland (QLD)">Queensland (QLD)</option>
                                            <option value="South Australia (SA)">South Australia (SA)</option>
                                            <option value="Tasmania (TAS)">Tasmania (TAS)</option>
                                            <option value="Victoria (VIC)">Victoria (VIC)</option>
                                            <option value="Western Australia (WA)">Western Australia (WA)</option>
                                        </select>
                                        {errors.DesiredLocation && (
                                            <small className="text-danger">{errors.DesiredLocation}</small>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div>
                                        <label className="form-label">Desired Salary</label>
                                        <div>
                                            <select className="form-select" aria-label="Default select example" value={formData.SalaryRange} name="SalaryRange" id="SalaryRangeasd" onChange={handleInputChange}>
                                                <option value="">--Select A Salary Range--</option>
                                                <option value="1">Under $30,000</option>
                                                <option value="2">$30,000 - $50,000</option>
                                                <option value="3">$50,000 - $70,000</option>
                                                <option value="4">$70,000 - $90,000</option>
                                                <option value="5">$90,000 - $110,000</option>
                                                <option value="6">$110,000 - $130,000</option>
                                                <option value="7">$130,000 - $150,000</option>
                                                <option value="8">$150,000 - $170,000</option>
                                                <option value="9">$170,000 - $190,000</option>
                                                <option value="10">$190,000 - $210,000</option>
                                                <option value="11">$210,000 - $230,000</option>
                                                <option value="12">$230,000 - $250,000</option>
                                                <option value="13">$250,000 and above</option>
                                                <option value="14">Negotiable</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value={formData.IsSponsorship}
                                            name="IsSponsorship"
                                            id="IsSponsorship"
                                            checked={formData.IsSponsorship}
                                            onChange={(e) =>
                                                setFormData({ ...formData, IsSponsorship: e.target.checked })
                                            }
                                        />
                                        <label className="form-check-label">Seeking Sponsorship</label>
                                    </div>
                                </div>

                                <div className="col-12 text-end">
                                    <button type="button" className="btn btn-sm btn-primary me-2 save-pref" id="save-pref" onClick={handleSubmit} disabled={saving}>{saving ? 'Saving...' : 'Save'}</button>
                                    {candidateData && (
                                        <button type="button" className="btn btn-sm btn-neutral me-2 cancel-pref" onClick={handleCancelClick}>Cancel</button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            ) : (
                <div className={`bnasdf_asldf pref-item${editing ? 'edit' : 'read'}`}>
                    <div className="card-body d-flex">
                        <div>
                            <p>
                                <span className="title-fleek">Desired Roles: </span><span className="pref_do">{formData.DesiredOccupation}</span>
                            </p>
                            <p>
                                    <span className="title-fleek">Desired Work Location: </span><span className="pref_do">{formData.DesiredLocation}</span>
                            </p>
                            <p>
                                    <span className="title-fleek">Desired Salary: </span><span className="pref_do">{getSalaryRangeText(formData.SalaryRange)} </span>
                            </p>
                            <p>
                                <span className="title-fleek">Are You Looking For A Sponsorship?: </span>
                                <span className="pref_is">
                                    {formData.IsSponsorship === true
                                        ? "Yes"
                                        : formData.IsSponsorship === false
                                            ? "No"
                                            : "N/A"}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            )}

        </div>
    );
}

export default CandidatePreferences;
