import React, { useState, useEffect,useContext } from 'react';
import { TokenContext } from '../../Context/TokenContext'; 
import { getApiBaseUrl } from '../../utils/Config';
import { AlertContext } from '../../Context/AlertContext';
import { useAuth0 } from '@auth0/auth0-react';

const CandidatePersonalSummary = ({ candidateID, candidateData }) => {
    const { isAuthenticated } = useAuth0();

    const { token, idTokenClaims } = useContext(TokenContext);
    const [editing, setEditing] = useState(false);
    const [saving, setSaving] = useState(false);
    const { showAlert } = useContext(AlertContext);

    const handleEditClick = () => {
        setEditing(true);
    };
    const baseUrl = getApiBaseUrl();

    const handleCancelClick = () => {
        setEditing(false);
    };
    const [formData, setFormData] = useState({
        Value: "",
        SummaryID:0,
    });
    const [errors, setErrors] = useState({
        Value: "",
    });
    useEffect(() => {
        if (candidateData) {
            setFormData({
                Value: candidateData.Value,
                SummaryID: candidateData.SummaryID,
            });
            setEditing(false);
        }
        else if (candidateData === null) {
            setEditing(true);
        }
    }, [candidateData]);
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    //Validation
    const validate = () => {
        const newErrors = {};
        // Check if summary is empty
        if (!formData.Value) {
            newErrors.Value = 'Summary is required.';
        }
        // Check if summary exceeds 2000 characters
        else if (formData.Value.length > 2000) {
            newErrors.Value = 'Summary should not exceed 2000 characters.';
        }

        // Check if summary contains special characters
        else if (/[^a-zA-Z0-9\s.,?!]/.test(formData.Value)) {
            newErrors.Value = 'Summary should not contain special characters except for basic punctuation (.,?!).';
        }

        setErrors(newErrors);
        return Object.values(newErrors).every(error => !error);
    };


    //Submit to API
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validate()) {
            if (saving) {
                return;
            }
            setSaving(true);
            const dataToPost = {
                Value: formData.Value,
                // Set other properties here
                CandidateID: candidateID,
                SummaryID: formData.SummaryID,
                DateTimeCreated: new Date().toISOString(),
                DateTimeModified: new Date().toISOString(),
                CreatedBy: 'Admin',
                ModifiedBy: 'User',
                IsDeleted: false,
                B2CObjectId: idTokenClaims.oid,
                Status: true
            };


            try {
                const response = await fetch(
                    `${baseUrl}/api/Summary/Upsert`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                        body: JSON.stringify(dataToPost),
                    }
                );
                const responseData = await response.json();

                if (responseData.success) {
                    const updatedCandidateSumID = responseData.result.SummaryID;

                    setFormData(prevFormData => ({
                        ...prevFormData,
                        SummaryID: updatedCandidateSumID,
                    }));
                    showAlert('Success', true);
                } else {
                    showAlert('Oops, something went wrong. Please try again later.', false);
                }
            }
            catch (error) {
                showAlert('Oops, something went wrong. Please try again later.', false);
            }
            finally {
                setSaving(false);
            }
        }
    };
    if (!isAuthenticated) {
        return <div>Please log in to access the dashboard.</div>;
    }

    return (

        <div className="summary-item">
            {!editing && (
                <div className="edit-icon">
                    <button type="button" className="btn px-2 edit-summary edit-but" onClick={handleEditClick}></button>
                </div>
            )}
            {editing ? (
                <form id="summary-form">
                    <div className="summary-item-edit">
                        <div className="summary-edit card-body-iajj">
                            <input type="hidden" className="form-control" defaultValue={formData.SummaryID} name="SummaryID" readOnly></input>
                            <input type="hidden" className="form-control" defaultValue={candidateID} name="candidateID" readOnly></input>
                            <div className="row g-5">
                                <div className="col-md-12">
                                    <div>
                                        <textarea type="text" className="form-control" value={formData.Value} name="Value" rows="8" cols="80" onChange={handleInputChange}>{formData.Value}</textarea>
                                        {errors.Value && (
                                            <small className="text-danger">{errors.Value}</small>
                                        )}
                                    </div>
                                </div>

                                <div className="col-12 text-end">
                                    <button type="button" className="btn btn-sm btn-primary me-2 save-summary" id="save-summary" onClick={handleSubmit} disabled={saving}>{saving ? 'Saving...' : 'Save'}</button>
                                    {candidateData && (
                                    <button type="button" className="btn btn-sm btn-neutral me-2" onClick={handleCancelClick}>Cancel</button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            ) : (
                <div className="bnasdf_asldf summary-item-read">
                    <div className="card-body d-flex">
                            <div>
                                <p className="sum-edit-go">{formData.Value}</p>
                        </div>
                    </div>
                </div>
            )}

        </div>
    );
}

export default CandidatePersonalSummary;
