// AlertContext.js
import React, { createContext, useState } from 'react';

export const AlertContext = createContext();

export const AlertProvider = ({ children }) => {
    const [alert, setAlert] = useState({ message: '', success: false, visible: false });

    const showAlert = (message, success) => {
        setAlert({ message, success, visible: true });
        setTimeout(() => setAlert({ ...alert, visible: false }), 8000);
    };

    return (
        <AlertContext.Provider value={{ alert, showAlert }}>
            {children}
        </AlertContext.Provider>
    );
};
