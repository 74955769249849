import React, { useState, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Alert from '../../utils/GenAlert';
import { auth0Config } from '../../utils/auth0Config'; // Import the config
import { getApiBaseUrl } from '../../utils/Config';
import { TokenContext } from '../../Context/TokenContext';
import { Modal, Button } from 'react-bootstrap'; // Import react-bootstrap

const Settings = () => {
    const { isAuthenticated, user, logout } = useAuth0();
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSuccess, setAlertSuccess] = useState(true);
    const [newEmail, setNewEmail] = useState('');
    const [showEmailInput, setShowEmailInput] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false); // Modal state for delete confirmation
    const { token, oid } = useContext(TokenContext);
    const baseUrl = getApiBaseUrl();

    const getManagementApiToken = async (scopes) => {
        const response = await fetch(`https://${auth0Config.domain}/oauth/token`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                client_id: auth0Config.APIClientId,
                client_secret: auth0Config.clientSecret,
                audience: auth0Config.managementApiAudience,
                grant_type: 'client_credentials',
                scope: scopes,
            })
        });

        const data = await response.json();
        if (!response.ok) {
            throw new Error(data.error || 'Failed to fetch management API token');
        }
        return data.access_token;
    };

    const deleteAuth0User = async (accessToken) => {
        const response = await fetch(`https://${auth0Config.domain}/api/v2/users/${user.sub}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        });

        if (!response.ok) {
            const data = await response.json();
            throw new Error(data.message || 'Failed to delete user in Auth0.');
        }
    };

    const handleDeleteAccount = async () => {
        try {
            // Delete account in your API
            const apiResponse = await fetch(`${baseUrl}/api/Authentication/DeleteAccount`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ b2cObjectId: oid })
            });

            const responseData = await apiResponse.json();
            if (!responseData.success) {
                throw new Error(responseData.message || 'Failed to delete account in the API.');
            }

            // Get Management API Token
            const accessToken = await getManagementApiToken('delete:users');

            // Delete user in Auth0
            await deleteAuth0User(accessToken);

            setAlertMessage('Account deleted successfully.');
            setAlertSuccess(true);

            // Sign out the user
            setTimeout(() => {
                logout({ returnTo: window.location.origin });
            }, 3000);
        } catch (error) {
            console.error('Error deleting account:', error);
            setAlertMessage(error.message || 'Failed to delete account. Please try again later.');
            setAlertSuccess(false);
        } finally {
            setShowAlert(true);
            setTimeout(() => setShowAlert(false), 5000);
        }
    };

    const updateAuth0Email = async (newEmail, accessToken) => {
        const response = await fetch(`https://${auth0Config.domain}/api/v2/users/${user.sub}`, {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: newEmail,
                email_verified: false
            })
        });

        if (!response.ok) {
            const data = await response.json();
            throw new Error(data.message || 'Failed to update email in Auth0.');
        }
    };

    const EmaildataToPost = {
        b2cObjectId: oid,
        newEmailChange: newEmail
    };

    const handleEmailChange = async (e) => {
        e.preventDefault();
        try {
            // Update email in your API
            const apiResponse = await fetch(
                `${baseUrl}/api/Authentication/ChangeEmail`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(EmaildataToPost),
                }
            );

            const responseData = await apiResponse.json();
            if (!responseData.success) {
                throw new Error(responseData.message || 'Failed to update email address in the API.');
            }

            // Get Management API Token
            const accessToken = await getManagementApiToken('update:users');

            // Update email in Auth0
            await updateAuth0Email(newEmail, accessToken);

            setAlertMessage('Email address updated successfully. Please log in with your new email.');
            setAlertSuccess(true);

            // Sign out the user to re-authenticate
            setTimeout(() => {
                logout({ returnTo: window.location.origin });
            }, 3000);
        } catch (error) {
            console.error('Error updating email address:', error);
            setAlertMessage(error.message || 'Failed to update email address. Please try again later.');
            setAlertSuccess(false);
        } finally {
            setShowAlert(true);
            setTimeout(() => setShowAlert(false), 5000);
        }
    };

    const triggerPasswordResetFlow = async () => {
        try {
            const accessToken = await getManagementApiToken('update:users');

            const response = await fetch(`https://${auth0Config.domain}/api/v2/tickets/password-change`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    user_id: user.sub,
                    client_id: auth0Config.APIClientId,
                })
            });

            if (response.ok) {
                setAlertMessage('Password reset email sent successfully.');
                setAlertSuccess(true);
            } else {
                const data = await response.json();
                throw new Error(data.message || 'Failed to send password reset email.');
            }
        } catch (error) {
            console.error('Error triggering password reset:', error);
            setAlertMessage(error.message || 'Failed to send password reset email. Please try again later.');
            setAlertSuccess(false);
        } finally {
            setShowAlert(true);
            setTimeout(() => setShowAlert(false), 5000);
        }
    };

    if (!isAuthenticated) {
        return <div>Please log in to access the dashboard.</div>;
    }

    return (
        <div className="flex-lg-1 h-screen overflow-y-lg-auto profile-lalk_lo">
            <main className="py-6 bg-surface-secondary">
                <div className="container-fluid max-w-screen-md vstack gap-6">
                    <div className="row g-6">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="mb-2 h4">Change Password</h4>
                                    <p className="text-sm text-muted mb-4">Click here to change your password.</p>
                                    <button type="button" className="btn btn-sm btn-primary" onClick={triggerPasswordResetFlow}>Change Password</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="mb-2 h4">Change Email</h4>
                                    <p className="text-sm text-muted mb-4">Changing your email address will require re-validation.</p>
                                    <button
                                        type="button"
                                        className="btn btn-sm btn-primary"
                                        onClick={() => setShowEmailInput(true)}
                                        disabled={showEmailInput}
                                    >
                                        Change Email
                                    </button>
                                    {showEmailInput && (
                                        <form onSubmit={handleEmailChange} className="mt-3">
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="newEmail">New Email Address</label>
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    id="newEmail"
                                                    value={newEmail}
                                                    onChange={(e) => setNewEmail(e.target.value)}
                                                    required
                                                />
                                            </div>
                                            <button type="submit" className="btn btn-sm btn-primary">Submit</button>
                                        </form>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="text-danger mb-2">Delete Account</h4>
                                    <p className="text-sm text-muted mb-4">Permanently remove your account and all of its contents. This action is not reversible - please be certain.</p>
                                    <button type="button" className="btn btn-sm btn-danger" onClick={() => setIsModalOpen(true)}>Delete my account</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {showAlert && <Alert message={alertMessage} success={alertSuccess} />}

                <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm Account Deletion</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Are you sure? By deleting your account, you will lose all history and access.</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => setIsModalOpen(false)}>No, keep my account</Button>
                        <Button variant="danger" onClick={handleDeleteAccount}>Yes, delete my account</Button>
                    </Modal.Footer>
                </Modal>
            </main>
        </div>
    );
};

export default Settings;
