import React, { useState, useEffect, useContext } from 'react';
import { Modal, Button } from 'react-bootstrap';
import ResumeAddForm from './ResumeAdd';
import CoverAddForm from './CoverAdd';
import CandidatePersonalDetails from './CandidatePersonalDetails'; // Import the CandidatePersonalDetails component
import { useParams } from 'react-router-dom';
import SkeletonLoader from '../../utils/SkeletonLoader';
import { TokenContext } from '../../Context/TokenContext';
import { getApiBaseUrl } from '../../utils/Config';
import { useAuth0 } from '@auth0/auth0-react';
import { AlertContext } from '../../Context/AlertContext';

const CandidateResumeApply = ({ candidateID, candidateDataResume, candidateJobTitle, candidateDataCover, candidateBusName, candidateBusID, apiDataDetails, refetchCandidateDetails }) => {
    const { isAuthenticated } = useAuth0();
    const { token } = useContext(TokenContext);
    const { showAlert } = useContext(AlertContext);
    const [showAddForm, setShowAddForm] = useState(false);
    const [showAddCLForm, setShowAddCLForm] = useState(false);
    const [resumeItems, setResumeItems] = useState(candidateDataResume || []);
    const [coverItems, setCoverItems] = useState(candidateDataCover || []);
    const [selectedResume, setSelectedResume] = useState('');
    const [saving, setSaving] = useState(false);
    const [selectedCover, setSelectedCover] = useState('');
    const [isCandidateDetailsIncomplete, setIsCandidateDetailsIncomplete] = useState(true);
    const { jobId } = useParams();
    const [feedbackMessage, setFeedbackMessage] = useState({ message: '', type: '', visible: false });
    const alertClassName = `alert ${feedbackMessage.type === 'success' ? 'alert-success' : 'alert-danger'}`;
    const baseUrl = getApiBaseUrl();
    console.log("details", apiDataDetails)
    // Check if candidate details are incomplete
    useEffect(() => {
        setIsCandidateDetailsIncomplete(
            !apiDataDetails || !apiDataDetails.CurrentRole || !apiDataDetails.Citizenship || !apiDataDetails.Location
        );
    }, [apiDataDetails]);
    const handleDetailsUpdated = () => {
        refetchCandidateDetails();
    };
    const handleResumeSelect = (event) => {
        setSelectedResume(event.target.value);
    };
    const handleCoverSelect = (event) => {
        setSelectedCover(event.target.value);
    };
    const handleAddCancelClick = () => {
        setShowAddForm(false);
    };
    const handleAddCancelCLClick = () => {
        setShowAddCLForm(false);
    };
    const updateResumeItems = (newResumeItem) => {
        setResumeItems((prevItems) => [...prevItems, newResumeItem]);
        setShowAddForm(false);
    };
    const updateCoverItems = (newCoverItem) => {
        setCoverItems((prevItems) => [...prevItems, newCoverItem]);
        setShowAddCLForm(false);
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        // Ensure candidate details are complete before proceeding
        if (isCandidateDetailsIncomplete) {
            showAlert('Please complete your profile details before applying for this job.', false);
            return;
        }

        if (!selectedResume) {
            setFeedbackMessage({ message: 'Please select a file before submitting', type: 'failure', visible: true });
            return;
        }
        setSaving(true);

        const payload = {
            CandidateID: candidateID,
            BusinessID: candidateBusID,
            JobPostID: jobId,
            Title: candidateJobTitle,
            ResumeID: selectedResume,
            //CoverLetterID: selectedCover || null,
            CoverLetterID: 2,
            ApplyDate: new Date().toISOString(),
            BusinessName: candidateBusName,
            CreatedBy: 'Admin',
            ModifiedBy: 'User',
            IsDeleted: false,
        };
        console.log("paload",payload);
        try {
            const response = await fetch(
                `${baseUrl}/api/AppliedJob/Upsert`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(payload),
                }
            );
            const responseData = await response.json();

            if (responseData.success) {
                setFeedbackMessage({ message: 'Application submitted successfully!', type: 'success', visible: true });
            } else {
                setFeedbackMessage({ message: responseData.message, type: 'failure', visible: true });
            }
        }
        catch (error) {
            setFeedbackMessage({ message: 'Oops, something went wrong. Please try again later.', type: 'failure', visible: true });
        }
        finally {
            setSaving(false);
        }
    };

    useEffect(() => {
        let timer;
        if (feedbackMessage.visible) {
            timer = setTimeout(() => {
                setFeedbackMessage(prevState => ({ ...prevState, visible: false }));
            }, 8000);
        }

        return () => clearTimeout(timer);
    }, [feedbackMessage.visible]);

    useEffect(() => {
        if (candidateDataResume) {
            setResumeItems(candidateDataResume);
        }
    }, [candidateDataResume]);

    useEffect(() => {
        if (candidateDataCover) {
            setCoverItems(candidateDataCover);
        }
    }, [candidateDataCover]);

    useEffect(() => {
        setIsCandidateDetailsIncomplete(
            !apiDataDetails || !apiDataDetails.CurrentRole || !apiDataDetails.Citizenship || !apiDataDetails.Location
        );
    }, [apiDataDetails]);

    if (!isAuthenticated) {
        return <div>Please log in to access the dashboard.</div>;
    }
    if (!candidateDataResume && !candidateDataResume) {
        return <SkeletonLoader />;
    }

    return (
        <>
            {feedbackMessage.message && feedbackMessage.visible && (
                <div className="alert-wrapper-below">
                    <div className={alertClassName} role="alert">
                        {feedbackMessage.message}
                    </div>
                </div>
            )}

            {isCandidateDetailsIncomplete && (
                <div className="vstack gap-6 resume-create-k_idj mt-7 css-dlxf46">
                    <div className="card">
                        <div className="card-header">
                            <h5>We are missing important information from you</h5>
                        </div>
                        <div className="card-body">
                            <CandidatePersonalDetails candidateID={candidateID} candidateData={apiDataDetails} onDetailsUpdated={handleDetailsUpdated} />
                        </div>
                    </div>
                </div>
            )}

            <div className="vstack gap-6 resume-create-k_idj mt-7 css-dlxf46">

                <div className="card">
                    <div className="card-header">
                        <h5>Resume</h5>
                    </div>
                    <div className="card-body">
                        <div className="col-md-12 mb-2">
                            <div className="row gx-4">
                                <div className="col">
                                    <div className="resume-select-box">
                                        <label htmlFor="resumeSelect">Select a Resume:</label>
                                        <select
                                            id="resumeSelect"
                                            className="form-select"
                                            value={selectedResume}
                                            onChange={handleResumeSelect}
                                            disabled={isCandidateDetailsIncomplete} // Disable if details are incomplete
                                        >
                                            <option value="">--Select Resume--</option>
                                            {resumeItems.map((resume) => (
                                                <option key={resume.AttachmentID} value={resume.AttachmentID}>
                                                    {resume.FileName}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-auto align-self-end">
                                    <span className="d-inline-block py-3 font-semibold text-muted">or</span>
                                </div>
                                <div className="col-auto align-self-end">
                                    <button type="button" className="btn btn-primary profile-button-w200" onClick={() => setShowAddForm(true)} disabled={isCandidateDetailsIncomplete}>
                                        <i className="bi bi-plus-lg"></i>
                                        <span className="d-none d-sm-inline ms-2">Upload New CV</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        {showAddForm && (
                            <ResumeAddForm
                                candidateID={candidateID}
                                token={token}
                                onCancel={handleAddCancelClick}
                                updateResumeItems={updateResumeItems}
                            />
                        )}
                    </div>
                </div>
            </div>

            <div className="vstack gap-6 resume-create-k_idj mt-7 css-dlxf46">
                <div className="card">
                    <div className="card-header">
                        <h5>Cover Letter</h5>
                    </div>
                    <div className="card-body">
                        <div className="col-md-12 mb-2">
                            <div className="row gx-4">
                                <div className="col">
                                    <div className="cover-select-box">
                                        <label htmlFor="coverSelect">Select a Cover Letter:</label>
                                        <select
                                            id="coverSelect"
                                            className="form-select"
                                            value={selectedCover}
                                            onChange={handleCoverSelect}
                                            disabled={isCandidateDetailsIncomplete} // Disable if details are incomplete
                                        >
                                            <option value="">--Select Cover Letter--</option>
                                            {coverItems.map((cover) => (
                                                <option key={cover.AttachmentID} value={cover.AttachmentID}>
                                                    {cover.FileName}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-auto align-self-end">
                                    <span className="d-inline-block py-3 font-semibold text-muted">or</span>
                                </div>
                                <div className="col-auto align-self-end">
                                    <button type="button" className="btn btn-primary profile-button-w200" onClick={() => setShowAddCLForm(true)} disabled={isCandidateDetailsIncomplete}>
                                        <i className="bi bi-plus-lg"></i>
                                        <span className="d-none d-sm-inline ms-2">Upload Cover Letter</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        {showAddCLForm && (
                            <CoverAddForm
                                candidateID={candidateID}
                                token={token}
                                onCancel={handleAddCancelCLClick}
                                updateCoverItems={updateCoverItems}
                            />
                        )}
                    </div>
                </div>
            </div>

            <div className="col-12 text-center mt-2">
                <button
                    type="submit"
                    className="btn btn-primary profile-button-w200"
                    disabled={!selectedResume || saving || isCandidateDetailsIncomplete} // Disable if details are incomplete
                    onClick={handleSubmit}
                >
                    {saving ? 'Saving...' : 'Submit'}
                </button>
            </div>
        </>
    );
};

export default CandidateResumeApply;
