import React, { useState, useEffect } from 'react';
import { useToken } from '../../utils/getToken';

const CandidateBusPersonalInfo = ({ candidateData, candidateDetails, candidateDataPref }) => {

    const firstName = candidateData.FirstName || 'N/A';
    const lastName = candidateData.LastName || 'N/A';
    const currentRole = (candidateDetails && candidateDetails.CurrentRole) || 'Not specified';
    const location = (candidateDetails && candidateDetails.Location) || 'Not specified';
    function getSalaryRangeText(salaryRangeValue) {
        const salaryRanges = {
            1: "Under $30,000",
            2: "$30,000 - $50,000",
            3: "$50,000 - $70,000",
            4: "$70,000 - $90,000",
            5: "$90,000 - $110,000",
            6: "$110,000 - $130,000",
            7: "$130,000 - $150,000",
            8: "$150,000 - $170,000",
            9: "$170,000 - $190,000",
            10: "$190,000 - $210,000",
            11: "$210,000 - $230,000",
            12: "$230,000 - $250,000",
            13: "$250,000 and above",
            14: "Negotiable",
            15: "Competitive",
        };

        return salaryRanges[salaryRangeValue] || "Not specified";
    }
    return (
        <div className="sf_bv_dkjoows mb-3">
            <div className="ss_sdl_dkjoows">
                <div className="">
                    <div className="dgfsdfgsdfg">
                        <h1 className="h3">
                            <span className="personInfo-fn">{firstName} </span> <span className="personInfo-ln">{lastName}</span>
                        </h1>
                        <h6 className="text-xs text-uppercase mb-1">{currentRole} - {location}</h6>
                        <div className="pers-pref-bus">
                            {candidateDataPref?.DesiredOccupation && (
                                <p>
                                    <span className="title-fleek">Desired Roles: </span><span className="pref_do">{candidateDataPref.DesiredOccupation}</span>
                                </p>
                            )}
                            {candidateDataPref?.DesiredLocation && (
                                <p>
                                    <span className="title-fleek">Desired Work Location: </span><span className="pref_dl">{candidateDataPref.DesiredLocation}</span>
                                </p>
                            )}
                            {candidateDataPref?.SalaryRange && (
                                <p>
                                    <span className="title-fleek">Desired Salary Range: </span><span className="pref_sl">{getSalaryRangeText(candidateDataPref.SalaryRange)}</span>
                                </p>
                            )}

                          
                            {candidateDataPref?.IsSponsorship === true && (
                                <p>
                                    <span className="pref_is">Seeking Sponsorship</span>
                                </p>
                            )}

                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default CandidateBusPersonalInfo;
