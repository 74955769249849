import { useState, useEffect } from 'react';
import { getApiBaseUrl } from '../utils/Config';

function useGetJobApps(token, apiCandidateID) {
    const [Jobs, setJobs] = useState([]); 
    const baseUrl = getApiBaseUrl();

    useEffect(() => {
        if (token && apiCandidateID) {
            const fetchJobs = async () => {
                try {
                    const response = await fetch(
                        `${baseUrl}/api/AppliedJob/GetByCandidateId?candidateId=${apiCandidateID}`,
                        {
                            mode: 'cors',
                            headers: {
                                "Authorization": `Bearer ${token}`
                            }
                        }
                    );
                    const data = await response.json();
                    setJobs(data.result);

                } catch (error) {
                    console.error("Failed to fetch resume URL:", error);
                }
            };

            fetchJobs();
        }
    }, [token, apiCandidateID, baseUrl]);

    return Jobs;
}

export default useGetJobApps;
