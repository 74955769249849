//
// Datepicker
// using the Flatpickr plugin
//

import flatpickr from "flatpickr";

export default {
	init: function (el) {
		var options = {
			enableTime: false,
			allowInput: true,
			wrap: true,
			dateFormat: 'M d, Y',
			position: 'right'
		};

		flatpickr(el, options);
	},
	range: function (el) {
		var options = {
			enableTime: false,
			allowInput: true,
			wrap: true,
			dateFormat: 'M d, Y',
			mode: 'range',
			defaultDate: ["Jan 23, 2022", "Jan 30, 2022"]
			// position: 'right'
		};

		flatpickr(el, options);
	}
};