import React, { useState, useEffect } from 'react';
import SkeletonLoader from '../../utils/SkeletonLoader';
import { useAuth0 } from '@auth0/auth0-react';

const CandidateBusSocials = ({ candidateData }) => {
    const { isAuthenticated } = useAuth0();
    const [socialItems, setSocialItems] = useState(candidateData || []);
    const linkstyle = "font-size: 1rem";
    const linkcolor = "color:white";
    useEffect(() => {
        if (candidateData) {
            setSocialItems(candidateData);
        }
    }, [candidateData]);

    useEffect(() => {
    }, [socialItems]);

    if (!isAuthenticated) {
        return <div>Please log in to access the dashboard.</div>;
    }
    if (!candidateData) {
        return <SkeletonLoader />;
    }
    return (
        <>
            {socialItems.map((social, index) => {
                return (
                    <a href={social.Link} target="_blank" key={social.SocialID} style={{ linkcolor }}>
                        <span className="d-inline-block me-3"><i className={`bi bi-${social.SocialName.toString().toLowerCase()}`} style={{ linkstyle }} ></i>
                        </span>
                    </a>
                );
            })}
        </>
    );
}

export default CandidateBusSocials;
