// Layout.js
import React, { useState, useEffect } from 'react';
import CandidateHeader from '../../pages/Candidate/CandidateHeader';
import Profile from '../../pages/Candidate/Profile';
import BusinessView from '../../pages/Candidate/BusinessView';
import CandidateJobApply from '../../pages/Candidate/JobApply';
import Settings from '../../pages/Candidate/Settings';
import Jobs from '../../pages/Candidate/Jobs';
import { Routes, Route } from 'react-router-dom';
import { AlertContext } from '../../Context/AlertContext';
import Alert from '../../utils/GenAlert';
function CandidateLayout() {
    return (
        <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
            <AlertContext.Consumer>
                {({ alert }) => (
                    <>
                        {alert.visible && <Alert message={alert.message} success={alert.success} />}
                    </>
                )}
            </AlertContext.Consumer>
            <CandidateHeader/>
            <Routes>
                <Route path="/profile" element={
                    <Profile
                    />
                } />
                <Route path="/businessview" element={
                    <BusinessView
                    />
                } />
                <Route path="/jobs" element={
                    <Jobs
                    />
                } />
                <Route path="/settings" element={
                    <Settings
                    />
                } />
                <Route path="/jobapply/:jobId" element={
                    <CandidateJobApply
                    />
                } />
            </Routes>
        </div>
    );
}

export default CandidateLayout;
